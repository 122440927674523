import { template as template_ac83a320dbf948c8a16f6da9c0c0eb39 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ac83a320dbf948c8a16f6da9c0c0eb39(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
